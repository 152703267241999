<template>
	<div style="height: 100%;overflow-y: scroll;padding-bottom: 60px" class="mob-privew">
		<vue-pdf-embed :source="pdfUrl" ref="pfdView" >1313</vue-pdf-embed>
	</div>
</template>


<script>
   // import VuePdfEmbed from 'vue-pdf-embed';
	import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
   export default {
      name: 'reportPdf',
		zhName:"pdf报告",
      components: {
         VuePdfEmbed
      },
      data() {
         return {
				loading:false,
				pdfUrl:""
         }
      },
      mounted() {
         
      },
      created() {
			let _pdfUrl = this.$route.query.pdfUrl;
			
			if(_pdfUrl){
				this.pdfUrl = _pdfUrl;
			}
			
      },
      methods: {


      }
   }
</script>

